.articlefix {
  position: relative;
  height: 2373px;
}

.s-hero__slider .newSlick-dots {
  display: block;
  list-style: none;
  width: 92%;
  max-width: var(--width-wide);
  padding: 0 3.2rem;
  margin-left: 0;
  margin-top: -15vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.s-hero__slider .newSlick-dots li {
  display: inline;
  padding-left: 0;
  margin-right: 0.8rem;
}

.s-hero__slider .newSlick-dots li::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  -webkit-transition: width 0.3s cubic-bezier(0.21, 0.97, 0.75, 0.85);
  transition: width 0.3s cubic-bezier(0.21, 0.97, 0.75, 0.85);
}

.s-hero__slider .newSlick-dots li button {
  display: inline-block;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0.4rem;
  line-height: var(--vspace-1);
  height: auto;
  color: white;
  position: relative;
}

.s-hero__slider .newSlick-dots li button::before {
  content: "0";
}

.s-hero__slider .newSlick-dots li:nth-child(n + 10) button::before {
  content: none;
}

.s-hero__slider .newSlick-dots li.slick-active::after {
  width: 32px;
  margin: 0 10px 0 4px;
}

@media screen and (max-width: 1200px) {
  .s-hero__slider .newSlick-dots {
    padding: 0 2.4rem;
  }
  .s-hero__slide-content > .column {
    -ms-flex: 0 0 55vw;
    -webkit-box-flex: 0;
    flex: 0 0 55vw;
    max-width: 55vw;
  }
  .s-hero__slide-text {
    font-size: 7rem;
  }
}

@media screen and (max-width: 800px) {
  .s-hero__slider .newSlick-dots {
    padding: 0 3rem;
  }
  .s-hero__slide-text {
    font-size: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .s-hero__slider .newSlick-dots {
    padding: 0 2.4rem;
  }
  .s-hero__slide-content > .column {
    -ms-flex: 0 0 75vw;
    -webkit-box-flex: 0;
    flex: 0 0 75vw;
    max-width: 75vw;
  }
  .s-hero__slide-text {
    font-size: 5.4rem;
  }
  .s-hero__social {
    font-size: 1.3rem;
    right: 2.8rem;
  }
  .s-hero__social-icons z {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .s-hero__slider .newSlick-dots {
    padding: 0 2rem;
  }
  .s-hero__slide-meta {
    font-size: var(--text-sm);
  }
  .s-hero__slide-text {
    font-size: 4.6rem;
  }
  .s-hero__social {
    right: 1.6rem;
  }
}

@media screen and (max-width: 400px) {
  .s-hero__slider .newSlick-dots {
    padding: 0 1rem;
  }
  .s-hero__slide-content {
    padding-bottom: 24vh;
  }
  .s-hero__slide-content > .column {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .s-hero__slide-text {
    font-size: 4.2rem;
  }
  .s-hero__nav-arrows button {
    height: var(--vspace-1_5);
    width: var(--vspace-1_75);
  }
  .s-hero__nav-arrows button svg {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.alert {
  text-align: center;
  padding: 5px;
  border: 1px solid black;
  background-color: #eeffee;
}
